export async function signIn(email, password) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
      },
      body: JSON.stringify(
        `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`
      ),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/auth/login/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zalogować",
    };
  }
}

export async function sendEmailNewPassword(email) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/user/forget-password/?email=" + email,
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zresetować hasła",
    };
  }
}

export async function SignUp(data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/auth/signup/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zarejestrować",
    };
  }
}

export async function getMyData(token) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/auth/me/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zalogować",
    };
  }
}

export async function updateProject(token, project_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/project/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function fetchCalendarItems(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/calendar/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function createCalendarItems(token, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data_in),
    };
    console.log(requestOptions);
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/calendar/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function deleteCalendarItem(token, data_id) {
  try {
    // Send request
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/calendar/" + data_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function getProjectAGDRTV(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/agdrtv/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function getProjectMaterialy(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/materialy/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function getProjectGwarancje(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/gwarancje/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function getProjectWyposazenie(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/wyposazenie/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function getProjectData(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/project/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function uploadInspiracjeImage(token, project_id, files) {
  try {
    let _formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      _formData.append("image", files[i], files[i].name);
    }
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: _formData,
    };

    const response = await fetch(
      "https://backend.panel.ossip.pl/api/inspiracje/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function uploadAdditionalImages(token, project_id, files) {
  try {
    let _formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      _formData.append("image", files[i], files[i].name);
    }
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: _formData,
    };

    const response = await fetch(
      "https://backend.panel.ossip.pl/api/additional-inspiracje/" +
        project_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function deleteInspiracjaImage(token, file_name) {
  try {
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      "https://backend.panel.ossip.pl/api/inspiracje/" + file_name + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function deleteAdditionalInspiracjaImage(token, file_name) {
  try {
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      "https://backend.panel.ossip.pl/api/additional-inspiracje/" +
        file_name +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function getListingsWithQ(token, project_id, q_string) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/" +
        project_id +
        "/" +
        q_string,
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function getListingsWithQWidok(token, project_id, listing_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/" +
        project_id +
        "/" +
        listing_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function uploadListing(token, project_id, q_string) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/" +
        project_id +
        "/" +
        q_string,
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function updateListing(token, listing_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/" + listing_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      console.log(data);

      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function updateListingWidok(
  token,
  listing_id,
  listing_widok_id,
  data_in
) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/" +
        listing_id +
        "/" +
        listing_widok_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      console.log(data);

      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function deleteListing(token, listing_id) {
  try {
    // Send request
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/" + listing_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function getListingFilesUrls(token, listing_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/" +
        listing_id +
        "/files_urls/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function toggleShoppingListRobocze(token, listing_id) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/shoppinglist/" +
        listing_id +
        "/robocze/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function listingToggleRobocze(token, listing_id) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/" + listing_id + "/robocze/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function uploadListingForm(token, project_id, file, name, type) {
  try {
    let _formData = new FormData();
    for (let i = 0; i < file.length; i++) {
      _formData.append("file", file[i], file[i].name);
    }
    _formData.append("name", name);
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: _formData,
    };

    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/" + project_id + "/" + type,
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function uploadListingFormWidok(
  token,
  project_id,
  listing_id,
  file
) {
  try {
    let _formData = new FormData();
    _formData.append("file", file, file.name);
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: _formData,
    };

    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/" +
        project_id +
        "/" +
        listing_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function deleteListingWidok(token, project_id, widok_id) {
  try {
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/" +
        project_id +
        "/" +
        widok_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function getListingWidokNote(
  token,
  project_id,
  listing_id,
  image_id
) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/" +
        project_id +
        "/" +
        listing_id +
        "/" +
        image_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function uploadProjectListingNoteReply(token, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/note/reply/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function uploadProjectListingNote(token, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/note/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function acceptListing(token, listing_id) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/accept/" + listing_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function acceptMaterialy(token, listing_id) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/materialy/accept/" + listing_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function acceptPoprawka(token, listing_id) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/poprawki/accept/" + listing_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    }
    return {
      isOk: false,
      message: data.detail,
    };
  } catch {
    return {
      isOk: false,
      message: "Coś poszło nie tak...",
    };
  }
}

export async function getPoprawkiData(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/poprawki/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function getPoprawkaData(token, project_id, poprawka_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/poprawki/" +
        project_id +
        "/" +
        poprawka_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function updatePoprawka_Data(token, poprawka_id, poprawka_in) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(poprawka_in),
    };
    console.log(requestOptions.body);
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/poprawki/" + poprawka_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function getPoprawkaNotesData(token, project_id, poprawka_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/poprawki/" +
        project_id +
        "/" +
        poprawka_id +
        "/notes/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function createPoprawkaNote(token, poprawka_id, poprawka_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(poprawka_in),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/poprawki/" + poprawka_id + "/notes/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function updatePoprawkaImage(token, project_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data_in,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/poprawki/" + project_id + "/image/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się dodać poprawki.",
    };
  }
}

export async function createPoprawka(token, project_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data_in,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/poprawki/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się dodać poprawki.",
    };
  }
}

export async function getSinglePoprawkaData(token, project_id, poprawka_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/poprawki/" +
        project_id +
        "/" +
        poprawka_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function createNewWyposazenie(token, project_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data_in,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/wyposazenie/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się wgrać CSV.",
    };
  }
}

export async function createNewGwarancje(token, project_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data_in,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/gwarancje/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function createNewMaterial(token, project_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data_in,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/materialy/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function createNewRTV(token, project_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data_in,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/agdrtv/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function updateRTV(token, rtv_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data_in,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/agdrtv/" + rtv_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function updateWyposazenie(token, wyposazenie_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data_in,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/wyposazenie/" + wyposazenie_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function updateMaterial(token, gwarancja_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data_in,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/materialy/" + gwarancja_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function updateGwarancje(token, gwarancja_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data_in,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/gwarancje/" + gwarancja_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function acceptAGDRTV(token, agdrtv_id) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/agdrtv/accept/" + agdrtv_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function deleteRTV(token, agdrtv_id) {
  try {
    // Send request
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/agdrtv/" + agdrtv_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function deleteWyposazenie(token, wyposazenie_id) {
  try {
    // Send request
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/wyposazenie/" + wyposazenie_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function deleteGwarancja(token, gwarancja_id) {
  try {
    // Send request
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/gwarancje/" + gwarancja_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function deleteMaterial(token, material_id) {
  try {
    // Send request
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/materialy/" + material_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function getProjectWykonawcy(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/wykonawca/project/" +
        project_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać wykonawców",
    };
  }
}

export async function getWykonawcyWithFiles(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/wykonawca/project/files/" +
        project_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać plików",
    };
  }
}

export async function crateWykonawcaFile(token, project_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data_in,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/wykonawca/file/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

export async function updateWykonawcaFile(token, wykonawca_file, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data_in,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/wykonawca/file/" +
        wykonawca_file +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

// ZAKRES PRAC I WYCENA

export async function getProjectZakresPrac(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/zakresprac/project/" +
        project_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać zakresu prac.",
    };
  }
}

export async function createZakresPrac(token, project_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data_in,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/zakresprac/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się wgrać zakresu prac.",
    };
  }
}

export async function ZakrestoggleFinish(token, zakres_prac_id) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/zakresprac/" +
        zakres_prac_id +
        "/finish/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zmienic statusu.",
    };
  }
}

export async function ZakrestoggleProgress(token, zakres_prac_id) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/zakresprac/" +
        zakres_prac_id +
        "/progress/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zmienic statusu.",
    };
  }
}

// ZALICZKI

export async function getProjectZaliczki(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/zaliczka/project/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać zakresu prac.",
    };
  }
}

export async function createZaliczka(token, project_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/zaliczka/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się utworzyć zaliczki.",
    };
  }
}

export async function update_zaliczka(token, zaliczka_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/zaliczka/" + zaliczka_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zedytować zaliczki.",
    };
  }
}

export async function removeWykonawcaFile(token, file_id) {
  try {
    // Send request
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/wykonawca/file/" + file_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać poprawek.",
    };
  }
}

// LISTA ZAKUPÓW

export async function createBudgetListaZakupow(token, project_id, _data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(_data_in),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/shoppinglist/json/" +
        project_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się utworzyć listy zakupów.",
    };
  }
}

export async function updateShoppingListOrder(token, shopping_list_id, order) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/shoppinglist/" +
        shopping_list_id +
        "/order/" +
        order +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zmienić kolejności.",
    };
  }
}

export async function createListaZakupow(token, project_id, _data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: _data_in,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/shoppinglist/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się utworzyć listy zakupów.",
    };
  }
}

export async function getListaAcceptedZakupow(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/shoppinglist/budget/" +
        project_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać listy zakupów.",
    };
  }
}
export async function getListaZakupow(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/shoppinglist/project/" +
        project_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać listy zakupów.",
    };
  }
}

export async function updateListaZakupow(token, item_id, item) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(item),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/shoppinglist/" + item_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zedytować listy zakupów.",
    };
  }
}

export async function removeListaZakupowRoom(token, project_id, room_name) {
  try {
    // Send request
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/shoppinglist/" +
        project_id +
        "/" +
        room_name +
        "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się usunąć pomieszczenia.",
    };
  }
}

export async function toggleChangeListaZakupow(token, item_id) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/shoppinglist/" + item_id + "/change/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się wymienić listy zakupów.",
    };
  }
}

export async function uploadImageShoppingList(token, item_id, from_data) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: from_data,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/shoppinglist/" + item_id + "/image/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się wymienić listy zakupów.",
    };
  }
}

export async function toggleAcceptBoughtListaZakupow(token, item_id) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/shoppinglist/" + item_id + "/bought/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się wymienić listy zakupów.",
    };
  }
}
export async function toggleChangeWymienBoughtListaZakupow(token, item_id) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/shoppinglist/" +
        item_id +
        "/change-budget/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się wymienić listy zakupów.",
    };
  }
}

export async function toggleAcceptListaZakupow(token, item_id) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/shoppinglist/" + item_id + "/accept/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zedytować listy zakupów.",
    };
  }
}

export async function removeListaZakupow(token, item_id) {
  try {
    // Send request
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/shoppinglist/" + item_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się wymienić listy zakupów.",
    };
  }
}

// CHAT

export async function getProjectThreads(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/thread/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać listy chatów.",
    };
  }
}

export async function getReceivers(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/thread/receivers/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać listy adresatów.",
    };
  }
}

export async function createMessageThread(token, project_id, message) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/thread/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się utworzyć wiadomości.",
    };
  }
}

export async function createSingleMessage(thread_id, message) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(message),
    };
    console.log(requestOptions.body);
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/thread/message/" + thread_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się utworzyć wiadomości.",
    };
  }
}

export async function createMessageFile(thread_id, message_id, files) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      body: files,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/thread/files/" +
        thread_id +
        "/" +
        message_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się utworzyć wiadomości.",
    };
  }
}

export async function getThreadMessages(thread_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/thread/messages/" + thread_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać wiadomości.",
    };
  }
}

// ANKIETY

export async function getAnkieta(token, project_id, ankieta_number) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/ankieta/" +
        project_id +
        "/" +
        ankieta_number +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać ankiety.",
    };
  }
}

export async function updateAnkieta(
  token,
  project_id,
  ankieta_number,
  data_in
) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/ankieta/" +
        project_id +
        "/" +
        ankieta_number +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać ankiety.",
    };
  }
}

export async function getDokumentyPageData(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/dokumenty/" + project_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać danych.",
    };
  }
}

export async function deletePoprawka(token, poprawka_id) {
  try {
    // Send request
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/poprawki/" + poprawka_id + "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się usunąć poprawki.",
    };
  }
}

export async function fetchSpecialistProjects(email) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/wykonawca/find-projects/?email=" +
        email,
      requestOptions
    );

    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się usunąć poprawki.",
    };
  }
}

export async function fetchUserProjects(token, user_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/project-user/" +
        (user_id ? user_id : "my-projects") +
        "/",
      requestOptions
    );

    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się usunąć poprawki.",
    };
  }
}

// OPENAI

export async function askChatGPT(token, question) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ question: question }),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/openai/",
      requestOptions
    );

    const data = await response.json();
    console.log(data);
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie możemy przetworzyć Twojego pytania.",
    };
  }
}

export async function removeZakresPracItem(token, item_id) {
  try {
    // Send request
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/zakresprac/" + item_id + "/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się usunąć zakresu prac.",
    };
  }
}

export async function remove_many_zakres_prac(token, item_id) {
  try {
    // Send request
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/zakresprac/" + item_id + "/many/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się usunąć zakresu prac.",
    };
  }
}

export async function update_zakres_prac_item(token, item_id, data_in) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data_in),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/zakresprac/" + item_id + "/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się usunąć zakresu prac.",
    };
  }
}

export async function updateWykonawcaProfilePicture(token, form_data) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: form_data,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/wykonawca/profile-picture/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się usunąć zakresu prac.",
    };
  }
}

export async function updateUserProfilePicture(token, form_data) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: form_data,
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/user/profile-picture/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się usunąć zakresu prac.",
    };
  }
}

export async function getMyWykonawcaData(token) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/wykonawca/find-me/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się usunąć zakresu prac.",
    };
  }
}

export async function sendSharedListing(token, listing_id, emails) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ emails: emails }),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/listing/share/" + listing_id + "/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się udostępnić plików",
    };
  }
}

export async function sendSharedMaterialy(token, listing_id, emails) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ emails: emails }),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/materialy/share/" + listing_id + "/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się udostępnić plików",
    };
  }
}

export async function sendSharedWyposazenie(token, listing_id, emails) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ emails: emails }),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/wyposazenie/share/" +
        listing_id +
        "/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się udostępnić plików",
    };
  }
}

export async function sendSharedListingSprzetAGD(token, project_id, emails) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ emails: emails }),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/agdrtv/share/" + project_id + "/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się udostępnić plików",
    };
  }
}

export async function sendSharedWykonawcaFiles(
  token,
  project_id,
  wykonawca_id,
  emails
) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ emails: emails }),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/wykonawca/share/" +
        project_id +
        "/" +
        wykonawca_id +
        "/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się udostępnić plików",
    };
  }
}

export async function sendSharedPoprawki(token, project_id, emails) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ emails: emails }),
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/poprawki/share/" + project_id + "/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się udostępnić plików",
    };
  }
}

export async function acceptZakresPracListing(token, zakres_prac_id) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/zakresprac/accept/" +
        zakres_prac_id +
        "/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zaakceptować",
    };
  }
}

export async function rejectZakresPracListing(token, zakres_prac_id) {
  try {
    // Send request
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/zakresprac/reject/" +
        zakres_prac_id +
        "/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się odrzucić",
    };
  }
}

export async function getInspiracje(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/inspiracje/" + project_id + "/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać inspiracji",
    };
  }
}

export async function getAdditionalInspiracje(token, project_id) {
  try {
    // Send request
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/additional-inspiracje/" +
        project_id +
        "/",
      requestOptions
    );

    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się pobrać inspiracji",
    };
  }
}

export async function handleZatwierdzZaliczka(token, zaliczka_id) {
  try {
    // Send request
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      "https://backend.panel.ossip.pl/api/zaliczka/zatwierdz/" +
        zaliczka_id +
        "/",
      requestOptions
    );
    const data = await response.json();
    if (response.ok) {
      return {
        isOk: true,
        data: data,
      };
    } else {
      return {
        isOk: false,
        message: data.detail,
      };
    }
  } catch {
    return {
      isOk: false,
      message: "Nie udało się zatwierdzić zaliczki.",
    };
  }
}
