import React, { Suspense, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { UserContext } from "./context/UserContext.js";
import ScrollToTop from "./ScrollToTop";
import { NotificationError } from "./components/Notification.js";

// ACCOUNTS
const Login = React.lazy(() => import("./pages/Accounts/Login/Index.js"));
const Register = React.lazy(() => import("./pages/Accounts/Register/Index.js"));
const ResetPassword = React.lazy(() =>
  import("./pages/Accounts/ForgetPassword/Index.js")
);

const MessagesLandingPage = React.lazy(() =>
  import("./pages/Panel/Komunikator/Komunikator.js")
);
// UserPanel
const Panel = React.lazy(() => import("./pages/Panel/Home/Index.js"));
const Ankiety = React.lazy(() => import("./pages/Panel/Ankiety/Index.js"));
const Ankieta1 = React.lazy(() => import("./pages/Panel/Ankiety/Ankieta1.js"));
const Ankieta2 = React.lazy(() => import("./pages/Panel/Ankiety/Ankieta2.js"));
const Ankieta3 = React.lazy(() => import("./pages/Panel/Ankiety/Ankieta3.js"));
const Ankieta3Wynik = React.lazy(() =>
  import("./pages/Panel/Ankiety/Ankieta3Wynik.js")
);

const Komunikator = React.lazy(() =>
  import("./pages/Panel/Komunikator/Index.js")
);
const Inspieracje = React.lazy(() =>
  import("./pages/Panel/Inspiracje/Index.js")
);
const Projekt = React.lazy(() => import("./pages/Panel/Projekt/Home.js"));
const ProjektListaZakupow = React.lazy(() =>
  import("./pages/Panel/Projekt/ListaZakupow.js")
);
const ProjektRysunkiTechniczne = React.lazy(() =>
  import("./pages/Panel/Projekt/RysunkiTechniczne.js")
);
const ProjektRysunkiTechniczneDetail = React.lazy(() =>
  import("./pages/Panel/Projekt/RysunkiTechniczneDetal.js")
);
const ProjektRysunkiTechniczneDetailImage = React.lazy(() =>
  import("./pages/Panel/Projekt/RysunkiTechniczneDetalImage.js")
);
const ProjektSprzetAGD = React.lazy(() =>
  import("./pages/Panel/Projekt/SprzetAGDRTV.js")
);
const ProjektUkladFunkcji = React.lazy(() =>
  import("./pages/Panel/Projekt/UkladFunkcji.js")
);
const ProjektUkladFunkcjiDetail = React.lazy(() =>
  import("./pages/Panel/Projekt/UkladFunkcjiDetal.js")
);
const ProjektUkladFunkcjiDetailImage = React.lazy(() =>
  import("./pages/Panel/Projekt/UkladFunkcjiDetalImage.js")
);
const ProjektWizualizacja = React.lazy(() =>
  import("./pages/Panel/Projekt/Wizualizacja.js")
);
const ProjektWizualizacjaDetail = React.lazy(() =>
  import("./pages/Panel/Projekt/WizualizacjaDetal.js")
);
const ProjektWizualizacjaDetailImage = React.lazy(() =>
  import("./pages/Panel/Projekt/WizualizacjaDetalImage.js")
);
const Realizacja = React.lazy(() => import("./pages/Panel/Realizacja/Home.js"));
const RealizacjaPoprawki = React.lazy(() =>
  import("./pages/Panel/Realizacja/Poprawki.js")
);
const RealizacjaPoprawkiDetail = React.lazy(() =>
  import("./pages/Panel/Realizacja/PoprawkaDetal.js")
);
const RealizacjaPostepPrac = React.lazy(() =>
  import("./pages/Panel/Realizacja/PostepPrac.js")
);
const RealizacjaPostepPracDetail = React.lazy(() =>
  import("./pages/Panel/Realizacja/PostepPracDetal.js")
);
const RealizacjaZakresPrac = React.lazy(() =>
  import("./pages/Panel/Realizacja/ZakresPracIWycena.js")
);

const BudzetIWydatki = React.lazy(() =>
  import("./pages/Panel/BudzetIWydatki/Home.js")
);
const BudzetIWydatkiMaterialy = React.lazy(() =>
  import("./pages/Panel/BudzetIWydatki/Materialy.js")
);
const BudzetIWydatkiWykonawcy = React.lazy(() =>
  import("./pages/Panel/BudzetIWydatki/Wykonawcy.js")
);
const BudzetIWydatkiZakupy = React.lazy(() =>
  import("./pages/Panel/BudzetIWydatki/Zakupy.js")
);

const Dokumenty = React.lazy(() => import("./pages/Panel/Dokumenty/Home.js"));
const DokumentyWykonawcy = React.lazy(() =>
  import("./pages/Panel/Dokumenty/Wykonawcy.js")
);
const DokumentyWykonwcyDokument = React.lazy(() =>
  import("./pages/Panel/Dokumenty/WykonawcyDokument.js")
);
const DokumentyWyposazenie = React.lazy(() =>
  import("./pages/Panel/Dokumenty/Wyposazenie.js")
);
const DokumentyWyposazenieDokument = React.lazy(() =>
  import("./pages/Panel/Dokumenty/WyposazenieDokument.js")
);
const DokumentyGwarancje = React.lazy(() =>
  import("./pages/Panel/Dokumenty/Gwarancje.js")
);
const KontaktPanel = React.lazy(() => import("./pages/Panel/Kontakt/Home.js"));

// Admin Panel
const Admin = React.lazy(() => import("./pages/Admin/Home/Index.js"));
const AdminAddUser = React.lazy(() => import("./pages/Admin/AddUser/Index.js"));
const AdminChecklist = React.lazy(() =>
  import("./pages/Admin/Checklist/Index.js")
);
const AdminTodo = React.lazy(() => import("./pages/Admin/Todo/Index.js"));
const AdminPowiadomienia = React.lazy(() =>
  import("./pages/Admin/Notifications/Index.js")
);
const AdminUsers = React.lazy(() => import("./pages/Admin/Users/Index.js"));
const AdminWykonawcy = React.lazy(() =>
  import("./pages/Admin/Wykonawcy/Index.js")
);
const AdminUser = React.lazy(() => import("./pages/Admin/User/Index.js"));
const AdminWykonawca = React.lazy(() =>
  import("./pages/Admin/Wykonawca/Index.js")
);
const AdminPracownicy = React.lazy(() =>
  import("./pages/Admin/Pracownicy/Index.js")
);
const AdminPracownik = React.lazy(() =>
  import("./pages/Admin/Pracownik/Index.js")
);
const AdminPracownikPrzypisz = React.lazy(() =>
  import("./pages/Admin/UserProjectPrzypisz/Index.js")
);

function App() {
  const authCtx = useContext(UserContext);
  console.log(authCtx);
  return (
    <Suspense fallback={""}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* CORE */}
          <Route
            path="/wiadomosci/:thread_id"
            element={<MessagesLandingPage />}
          />
          {authCtx.isLoggedIn && (
            <>
              <Route index path="/:project_id" element={<Panel />} />
              <Route path="/:project_id/ankiety" element={<Ankiety />} />
              <Route path="/:project_id/ankiety/1" element={<Ankieta1 />} />
              <Route path="/:project_id/ankiety/2" element={<Ankieta2 />} />
              <Route path="/:project_id/ankiety/3" element={<Ankieta3 />} />
              <Route
                path="/:project_id/ankiety/3/wynik"
                element={<Ankieta3Wynik />}
              />
              <Route
                path="/:project_id/komunikator"
                element={<Komunikator />}
              />
              <Route path="/:project_id/inspiracje" element={<Inspieracje />} />
              <Route path="/:project_id/projekt" element={<Projekt />} />
              <Route
                path="/:project_id/projekt/lista-zakupow"
                element={<ProjektListaZakupow />}
              />
              <Route
                path="/:project_id/projekt/rysunki-techniczne"
                element={<ProjektRysunkiTechniczne />}
              />
              <Route
                path="/:project_id/projekt/rysunki-techniczne/:listing_id"
                element={<ProjektRysunkiTechniczneDetail />}
              />
              <Route
                path="/:project_id/projekt/rysunki-techniczne/:listing_id/:image_id"
                element={<ProjektRysunkiTechniczneDetailImage />}
              />
              <Route
                path="/:project_id/projekt/sprzet-agd"
                element={<ProjektSprzetAGD />}
              />
              <Route
                path="/:project_id/projekt/uklad-funkcji"
                element={<ProjektUkladFunkcji />}
              />
              <Route
                path="/:project_id/projekt/uklad-funkcji/:listing_id"
                element={<ProjektUkladFunkcjiDetail />}
              />
              <Route
                path="/:project_id/projekt/uklad-funkcji/:listing_id/:image_id"
                element={<ProjektUkladFunkcjiDetailImage />}
              />
              <Route
                path="/:project_id/projekt/wizualizacja"
                element={<ProjektWizualizacja />}
              />
              <Route
                path="/:project_id/projekt/wizualizacja/:listing_id"
                element={<ProjektWizualizacjaDetail />}
              />
              <Route
                path="/:project_id/projekt/wizualizacja/:listing_id/:image_id"
                element={<ProjektWizualizacjaDetailImage />}
              />
              <Route path="/:project_id/realizacja" element={<Realizacja />} />
              {authCtx.user && !authCtx.user.is_worker && (
                <>
                  <Route
                    path="/:project_id/realizacja/poprawki"
                    element={<RealizacjaPoprawki />}
                  />
                  <Route
                    path="/:project_id/realizacja/poprawki/:poprawka_id"
                    element={<RealizacjaPoprawkiDetail />}
                  />
                  <Route
                    path="/:project_id/realizacja/zakres-prac"
                    element={<RealizacjaZakresPrac />}
                  />
                  <Route
                    path="/:project_id/budzet-i-wydatki/materialy"
                    element={<BudzetIWydatkiMaterialy />}
                  />
                  <Route
                    path="/:project_id/budzet-i-wydatki/wykonawcy"
                    element={<BudzetIWydatkiWykonawcy />}
                  />
                  <Route
                    path="/:project_id/dokumenty"
                    element={<Dokumenty />}
                  />
                  <Route
                    path="/:project_id/dokumenty/wykonawcy"
                    element={<DokumentyWykonawcy />}
                  />
                  <Route
                    path="/:project_id/dokumenty/wykonawcy/:dokument_id"
                    element={<DokumentyWykonwcyDokument />}
                  />
                  <Route
                    path="/:project_id/dokumenty/rtv-agd"
                    element={<DokumentyWyposazenie />}
                  />
                  <Route
                    path="/:project_id/dokumenty/rtv-agd/:dokument_id"
                    element={<DokumentyWyposazenieDokument />}
                  />
                  <Route
                    path="/:project_id/dokumenty/wyposazenie"
                    element={<DokumentyGwarancje />}
                  />
                  <Route
                    path="/:project_id/kontakt"
                    element={<KontaktPanel />}
                  />
                </>
              )}

              <Route
                path="/:project_id/realizacja/postep-prac"
                element={<RealizacjaPostepPrac />}
              />
              <Route
                path="/:project_id/realizacja/postep-prac/:listing_id"
                element={<RealizacjaPostepPracDetail />}
              />

              <Route
                path="/:project_id/budzet-i-wydatki"
                element={<BudzetIWydatki />}
              />

              <Route
                path="/:project_id/budzet-i-wydatki/zakupy"
                element={<BudzetIWydatkiZakupy />}
              />
            </>
          )}

          {/* Accounts */}
          <Route path="/zaloguj-sie" element={<Login />} />
          <Route path="/zarejestruj-sie" element={<Register />} />
          <Route path="/przypomnij-haslo" element={<ResetPassword />} />

          {authCtx.isLoggedIn &&
            authCtx.user &&
            (authCtx.user.is_admin || authCtx.user.is_worker) && (
              <>
                {/* ADMIN */}

                <Route path="/admin" element={<Admin />} />
                <Route path="/admin/dodaj" element={<AdminAddUser />} />
                <Route path="/admin/todo" element={<AdminTodo />} />
                <Route path="/admin/checklist" element={<AdminChecklist />} />
                <Route
                  path="/admin/powiadomienia"
                  element={<AdminPowiadomienia />}
                />
                <Route path="/admin/uzytkownicy" element={<AdminUsers />} />
                <Route path="/admin/wykonawcy" element={<AdminWykonawcy />} />
                <Route
                  path="/admin/uzytkownicy/:user_id"
                  element={<AdminUser />}
                />
                <Route
                  path="/admin/wykonawcy/:wykonawca_id"
                  element={<AdminWykonawca />}
                />
                <Route
                  path="/admin/pracownicy/"
                  element={<AdminPracownicy />}
                />
                <Route
                  path="/admin/pracownicy/:user_id"
                  element={<AdminPracownik />}
                />
                <Route
                  path="/admin/pracownicy/:user_id/przypisz"
                  element={<AdminPracownikPrzypisz />}
                />
              </>
            )}
          <Route path="/" element={<Navigate to="/zaloguj-sie" />} />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
