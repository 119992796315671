import React, { createContext, useEffect, useState, useCallback } from "react";
import { getMyData } from "../api/user";

let logoutTimer;

export const UserContext = createContext({
  token: "",
  isLoggedIn: false,
  userSet: () => {},
  user: {},
  logout: () => {},
  login: () => {},
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  return adjExpirationTime - currentTime;
};

const retrieveStoredToken = () => {
  const userToken = localStorage.getItem("OssipPanelToken");
  const storedExpirationDate = localStorage.getItem("ExpirationTime");

  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600) {
    localStorage.removeItem("OssipPanelToken");
    localStorage.removeItem("ExpirationTime");
    return null;
  }

  return {
    token: userToken,
    duration: remainingTime,
  };
};

const retrieveStoredUser = () => {
  const user = localStorage.getItem("OssipPanelUser");

  return user;
};

const removeStoredUser = () => {
  localStorage.removeItem("OssipPanelUser");
  return null;
};

export const getBoolStorage = (item) => {
  return localStorage.getItem(item) === "true";
};

export const UserProvider = (props) => {
  const tokenData = retrieveStoredToken();
  const userData = retrieveStoredUser();

  let initialToken;
  if (tokenData) {
    initialToken = tokenData.token;
  }
  let initialUser;
  if (userData) {
    initialUser = JSON.parse(userData);
  }

  const [token, setToken] = useState(initialToken);
  const [user, setUser] = useState(initialUser);

  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    setToken(null);
    setUser(removeStoredUser());
    localStorage.removeItem("OssipPanelToken");
    localStorage.removeItem("ExpirationTime");
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const userSetHandler = async () => {
    let user = await getMyData(token);
    if (user.isOk) {
      setUser(user.data);
      localStorage.setItem("OssipPanelUser", JSON.stringify(user.data));
    }
  };

  const loginHandler = async (token, expirationTime) => {
    setToken(token);
    localStorage.setItem("OssipPanelToken", token);
    localStorage.setItem("ExpirationTime", expirationTime);

    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
    let user = await getMyData(token);
    if (user.isOk) {
      setUser(user.data);
      localStorage.setItem("OssipPanelUser", JSON.stringify(user.data));
    }
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    user: user,
    token: token,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    userSet: userSetHandler,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
};
